import React, { useState } from 'react';
import { KeyValue } from 'mediadb-models';
import { useEffectOnce } from 'react-use';
import * as journalistSearchLogService from '@/services/journalist-search-log.service';
import Highcharts from 'highcharts';
import { defaultChartOptions } from '@/components/charts-options';
import HighchartsReact from 'highcharts-react-official';

export default function JournalistParamsCount({fromDate}: {
    fromDate: Date
}) {
    const [journalistParamsCount, setJournalistParamsCount] = useState<KeyValue[]>([]);

    useEffectOnce(() => {
        journalistSearchLogService.getParamsCount(fromDate).then(setJournalistParamsCount);
    });

    const journalistParamsCountOptions: Highcharts.Options = {
        ...defaultChartOptions,
        chart: {
            type: 'column',
        },
        title: {
            text: 'Journalist Params Count'
        },
        xAxis: {
            categories: journalistParamsCount.map(kv => kv.key),
            crosshair: true,
        },
        yAxis: {
            min: 0,
            gridLineDashStyle: 'Dash',
            allowDecimals: false,
            title: {
                text: 'Count'
            }
        },
        series: [
            {
                type: 'column',
                name: 'Query Count',
                data: journalistParamsCount.map(a => ({
                    y: a.value
                })),
            }
        ],
    };

    return <HighchartsReact highcharts={Highcharts} options={journalistParamsCountOptions}/>;
}
