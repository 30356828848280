import React, { useState } from 'react';
import { KeyValue } from 'mediadb-models';
import { useEffectOnce } from 'react-use';
import * as journalistSearchLogService from '@/services/journalist-search-log.service';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Wordcloud from 'highcharts/modules/wordcloud';

export default function JournalistTopicsCount({fromDate}: {
    fromDate: Date
}) {
    const [journalistTopicsCount, setJournalistTopicsCount] = useState<KeyValue[]>([]);

    useEffectOnce(() => {
        journalistSearchLogService.getTopicsCount(fromDate).then(setJournalistTopicsCount);
    });

    Wordcloud(Highcharts);

    const journalistTopicsCountOptions: Highcharts.Options = {
        chart: {
            type: 'wordcloud',
        },
        title: {
            text: 'Journalist Topics Count'
        },
        series: [
            {
                type: 'wordcloud',
                name: 'Topics Count',
                minFontSize: 15,
                data: journalistTopicsCount.map(a => ({
                    name: a.key,
                    weight: a.value
                })),
            }
        ],
    };

    return <HighchartsReact highcharts={Highcharts} options={journalistTopicsCountOptions}/>;
}
