import React, { useState } from 'react';
import { KeyValue } from 'mediadb-models';
import { useEffectOnce } from 'react-use';
import * as journalistSearchLogService from '@/services/journalist-search-log.service';
import * as outletSearchLogService from '@/services/outlet-search-log.service';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import { defaultChartOptions, isSeriesHidden } from '@/components/charts-options';
import HighchartsReact from 'highcharts-react-official';

export default function QueryTookAverage({fromDate}: {
    fromDate: Date
}) {
    const [journalistTookAverage, setJournalistTookAverage] = useState<KeyValue[]>([]);
    const [outletTookAverage, setOutletTookAverage] = useState<KeyValue[]>([]);

    useEffectOnce(() => {
        journalistSearchLogService.getTookAverage(fromDate).then(setJournalistTookAverage);
        outletSearchLogService.getTookAverage(fromDate).then(setOutletTookAverage);
    });

    const chartTitle = 'Average Query Time';

    const tookAverageOptions: Highcharts.Options = {
        ...defaultChartOptions,
        chart: {
            type: 'line',
            ...defaultChartOptions.chart,
        },
        title: {
            text: chartTitle
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            gridLineDashStyle: 'Dash',
            allowDecimals: false,
            title: {
                text: 'MS'
            }
        },
        series: [
            {
                type: 'line',
                name: 'Journalist Average Query Time',
                data: journalistTookAverage.map(a => ({
                    x: new Date(a.key).getTime(),
                    y: a.value
                })),
            },
            {
                type: 'line',
                name: 'Outlet Average Query Time',
                data: outletTookAverage.map(a => ({
                    x: new Date(a.key).getTime(),
                    y: a.value
                })),
            }
        ].map(s => ({
            ...s,
            visible: !isSeriesHidden(chartTitle, s.name)
        } as SeriesOptionsType)),
    };

    return <HighchartsReact highcharts={Highcharts} options={tookAverageOptions}/>;
}
