import request from '@/request/request';
import { JournalistStat } from 'mediadb-models';

const urls = {
    get: '/journalist-stat',
}

export function getJournalistStats(fromDate: Date) {
    return request.get<JournalistStat[]>(urls.get, {
        params: {
            fromDate
        }
    }).then(res => res.data);
}
