import request from '@/request/request';
import { KeyValue } from 'mediadb-models';

const urls = {
    get: '/outlet-search-log',
    tookAverage: '/outlet-search-log/took/average',
    queryCount: '/outlet-search-log/query/count',
    paramsCount: '/outlet-search-log/params/count',
    topicsCount: '/outlet-search-log/topics/count',
    sortCount: '/outlet-search-log/sort/count',
    userQueryCount: '/outlet-search-log/user/query/count',
    userOutletCount: '/outlet-search-log/user/outlet/count',
}

export function getTookAverage(fromDate: Date) {
    return request.get<KeyValue[]>(urls.tookAverage, {
        params: {
            fromDate
        }
    }).then(res => res.data);
}

export function getQueryCount(fromDate: Date) {
    return request.get<KeyValue[]>(urls.queryCount, {
        params: {
            fromDate
        }
    }).then(res => res.data);
}

export function getParamsCount(fromDate: Date) {
    return request.get<KeyValue[]>(urls.paramsCount, {
        params: {
            fromDate
        }
    }).then(res => res.data);
}

export function getTopicsCount(fromDate: Date) {
    return request.get<KeyValue[]>(urls.topicsCount, {
        params: {
            fromDate
        }
    }).then(res => res.data);
}

export function getSortCount(fromDate: Date) {
    return request.get<KeyValue[]>(urls.sortCount, {
        params: {
            fromDate
        }
    }).then(res => res.data);
}

export function getUserQueryCount(fromDate: Date) {
    return request.get<KeyValue[]>(urls.userQueryCount, {
        params: {
            fromDate
        }
    }).then(res => res.data);
}

export function getUserOutletCount(fromDate: Date) {
    return request.get<KeyValue[]>(urls.userOutletCount, {
        params: {
            fromDate
        }
    }).then(res => res.data);
}
