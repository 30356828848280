import request from '@/request/request';
import { KeyValue } from 'mediadb-models';

const urls = {
    get: '/journalist-search-log',
    tookAverage: '/journalist-search-log/took/average',
    queryCount: '/journalist-search-log/query/count',
    paramsCount: '/journalist-search-log/params/count',
    topicsCount: '/journalist-search-log/topics/count',
    sortCount: '/journalist-search-log/sort/count',
    userQueryCount: '/journalist-search-log/user/query/count',
    userJournalistCount: '/journalist-search-log/user/journalist/count',
}

export function getTookAverage(fromDate: Date) {
    return request.get<KeyValue[]>(urls.tookAverage, {
        params: {
            fromDate
        }
    }).then(res => res.data);
}

export function getQueryCount(fromDate: Date) {
    return request.get<KeyValue[]>(urls.queryCount, {
        params: {
            fromDate
        }
    }).then(res => res.data);
}

export function getParamsCount(fromDate: Date) {
    return request.get<KeyValue[]>(urls.paramsCount, {
        params: {
            fromDate
        }
    }).then(res => res.data);
}

export function getTopicsCount(fromDate: Date) {
    return request.get<KeyValue[]>(urls.topicsCount, {
        params: {
            fromDate
        }
    }).then(res => res.data);
}

export function getSortCount(fromDate: Date) {
    return request.get<KeyValue[]>(urls.sortCount, {
        params: {
            fromDate
        }
    }).then(res => res.data);
}

export function getUserQueryCount(fromDate: Date) {
    return request.get<KeyValue[]>(urls.userQueryCount, {
        params: {
            fromDate
        }
    }).then(res => res.data);
}

export function getUserJournalistCount(fromDate: Date) {
    return request.get<KeyValue[]>(urls.userJournalistCount, {
        params: {
            fromDate
        }
    }).then(res => res.data);
}
